<template>
  <div class="tip">
    <el-row :gutter="20" type="flex" justify="center" align="middle">
      <el-col :span="4">
        <i class="el-icon-arrow-left" @click="back"></i>
      </el-col>
      <el-col :span="16">
        <h2 class="text-center">什么是网培师</h2>
      </el-col>
      <el-col :span="4"> </el-col>
    </el-row>
    <div class="img">
      <img src="../../assets/images/home/img1.png" alt="" />
    </div>
    <div class="cont">
      <div class="txt">
        <p>
          网培师，指依托互联网平台从事培训咨询和教学活动的人员，是在国内推进在线教育发展及人社部推出在线教育相关新职业岗位的背景下，由上海市培训协会、上海人才服务行业协会、上海现代服务业联合会互联网科创专委会和网班教育评测中心等共同发起和组织，并由中国商业联合会在国内设立的专业能力评价认证项目。
        </p>
        <p>
          随着在线经济的蓬勃发展，特别是后疫情时代，在线教育的需求得到催化和爆发，急需建立相关从业人员的职业水平标准和管理规范，提升在线教育培训从业人员的专业水平和执业能力。
        </p>
        <p>
          网培师能力评测标准和体系的建设，得到了国内主流教育培训机构的广泛响应和参与。相关的培训及评测认证工作，也为希望从事在线培训行业的人才提供了一个职业发展的良好平台。
        </p>
        <p>
          网培师项目并与联合国训研所UNITAR中国中心等国际机构建立了协作关系，符合条件的网培师可进一步申请获得联合国训研所的相关证书并进入其官方人才库。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="less" scoped>
.tip {
  width: 100%;
  i {
    font-size: 30px;
  }
  h2 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    margin: 20px 0px;
  }
  .img {
    width: 60vw;
    margin: 0px auto;

    img {
      width: 100%;
    }
  }
  .cont {
    width: 100%;
    padding-top: 130px;
    padding-bottom: 20px;
    background-color: #fcf8f5;
    position: absolute;
    top: 250px;
    z-index: -1;
  }
  .txt {
    max-width: 85vw;
    margin: 0px auto;
    p {
      margin-top: 15px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c8087;
      line-height: 24px;
    }
  }
}
</style>
